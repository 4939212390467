import Vue from "vue";
import App from "./App.vue";
import VueAnimate from "vue-animate-scroll";
// import VModal from "vue-js-modal";
// import VueCarousel from "vue-carousel";
import vuetify from '@/plugins/vuetify'
Vue.config.productionTip = false;
Vue.use(VueAnimate)
// Vue.use(VueCarousel);

Vue.use(VueAnimate);
// Vue.use(VModal);
new Vue({vuetify,
	render: (h) => h(App),
}).$mount("#app");
