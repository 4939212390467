<template>
  <section id="CAREERS" class="bg-white">
    <div class="">
      <img
        class="mx-auto lg:block hidden w-[53%]"
        src="../assets/who_we_need/who_we_need.png"
      />
      <img
        id=""
        class="mx-auto lg:hidden py-[5vh] w-[480px]"
        src="../assets/who_we_need/h5whoweneed.png"
      />
      <div
        class="md:text-2xl text-md md:text-start text-center w-[80%] mx-auto text-[#999999] mb-[80px]"
      >
        We build a better world with Information Technology. And as we arise in
        providing the global market of outstanding software development and
        innovative application services. We're looking for professionals who are
        willing to showcase their skills in accord to cater the needs of our
        valued international and local clients.
      </div>
      <div>
        <ul
          class="list-none md:flex hidden justify-center items-center my-4 text-center text-2xl text-[#B7B7B7] xl:w-full w-[90%] mx-auto max-w-[1150px] ]"
        >
          <li
            v-for="(item, index) in role_label"
            :key="'A' + index"
            class="w-full cursor-pointer py-2 relative tracking-wider"
            @click="setActiveRole(item)"
            :class="
              item === active_role
                ? 'font-bold text-[#FAAD14] activeJob '
                : 'font-normal '
            "
          >
            <div
              class="borderTop"
              :class="
                index === 0
                  ? 'borderDuloLeft'
                  : index === 4
                  ? 'borderDuloRight'
                  : ''
              "
            ></div>

            {{ item }}
          </li>
        </ul>
        <div
          class="md:border-x-[0px] md:border-t-[0px] border-t-[1.5px] border-x-[1.5px] rounded-2xl w-[90%] mx-auto md:my-0 my-4"
        >
          <div
            class="shadow-sm mb-1 md:hidden border-b-[2px] px-2 py-3 mx-auto space-y-2"
          >
            <button
              v-for="(item, index) in role_label"
              :key="'B' + index"
              @click="setActiveRole(item)"
            >
              <div
                class="cursor-pointer border-2 bg-transparent mx-1 rounded-full px-4 py-1 text-base transition duration-500 ease select-none tracking-wider focus:outline-0 active:outline-0 hover:outline-0"
                :class="
                  item === active_role
                    ? 'border-[#FAAD14] text-[#FAAD14] font-bold bg-[#FAAD141A]/[.1]'
                    : 'border-[#DFDFDF] text-[#666666] font-normal'
                "
              >
                {{ item }}
              </div>
            </button>
          </div>

          <TransitionGroup
            name="cards"
            tag="div"
            class="md:grid hidden w-full xl:grid-cols-3 grid-cols-2 gap-6 rounded-2xl w-[80%] mx-auto mb-[150px] max-w-[1150px]"
          >
            <template v-for="(itemUpper, index) in roleData">
              <template v-if="active_role === 'All'">
                <template v-for="(item, indexX) in itemUpper.details">
                  <template v-if="loadMore">
                    <CardsComp
                      v-if="index < 3 && indexX < 3"
                      v-bind:key="'C-' + index + '-' + indexX"
                      :index="index"
                      :indexX="indexX"
                      :itemUpper="itemUpper"
                      :roleIcon="roleIcon"
                      :item="item"
                      :showModalMethod="showModalMethod"
                    />
                  </template>
                  <template v-if="!loadMore">
                    <CardsComp
                      v-bind:key="'D-' + index + '-' + indexX"
                      :index="index"
                      :indexX="indexX"
                      :itemUpper="itemUpper"
                      :roleIcon="roleIcon"
                      :item="item"
                      :showModalMethod="showModalMethod"
                    />
                  </template>
                </template>
              </template>
              <template v-if="itemUpper.role === active_role.toLowerCase()">
                <template v-for="(item, indexX) in itemUpper.details">
                  <template>
                    <CardsComp
                      v-bind:key="'E' + indexX + index"
                      :index="index"
                      :indexX="indexX"
                      :itemUpper="itemUpper"
                      :roleIcon="roleIcon"
                      :item="item"
                      :showModalMethod="showModalMethod"
                    />
                  </template>
                </template>
              </template>
            </template>
          </TransitionGroup>
          <TransitionGroup
            name="cards"
            tag="div"
            id="mobileJobPosting"
            class="md:hidden grid grid-cols-1 border-b-[5px] rounded-2xl mx-auto md:mb-[150px] mb-[-30px] max-h-[750px] overflow-y-scroll"
          >
            <template v-for="(itemUpper, index) in roleData">
              <template v-if="active_role === 'All'">
                <template v-for="(item, indexX) in itemUpper.details">
                  <template>
                    <CardsCompH5
                      v-bind:key="'F-' + index + '-' + indexX"
                      :indexX="indexX"
                      :itemUpper="itemUpper"
                      :roleIcon="roleIcon"
                      :item="item"
                      :showModalMethod="showModalMethod"
                    />
                  </template>
                </template>
              </template>
              <template v-if="itemUpper.role === active_role.toLowerCase()">
                <template v-for="(item, indexX) in itemUpper.details">
                  <template>
                    <CardsCompH5
                      v-bind:key="'G' + indexX + index"
                      :indexX="indexX"
                      :itemUpper="itemUpper"
                      :roleIcon="roleIcon"
                      :item="item"
                      :showModalMethod="showModalMethod"
                    />
                  </template>
                </template>
              </template>
            </template>
          </TransitionGroup>
        </div>
        <div v-if="active_role === 'All'" class="md:flex hidden justify-center">
          <a v-if="loadMore" href="#CAREERS">
            <div
              @click="loadMoreModal"
              class="font-bold border-2 border-[#22539C] bg-transparent text-[#22539C] rounded-full px-10 py-4 text-2xl transition duration-500 ease select-none hover:bg-[#C5DCFF80]/[.5] focus:bg-[#22539C] focus:text-[#103060]"
            >
              Load More
            </div>
          </a>
          <a v-if="!loadMore" href="#CAREERS">
            <div
              @click="loadMoreModal"
              class="font-bold border-2 border-[#22539C] bg-transparent text-[#22539C] rounded-full px-10 py-4 text-2xl transition duration-500 ease select-none hover:bg-[#C5DCFF80]/[.5] focus:bg-[#22539C] focus:text-[#103060]"
            >
              Show Less
            </div>
          </a>
        </div>
        <!-- -->
        <div
          class="px-4 lg:my-[150px] my-[75px] flex flex-row flex-wrap justify-center items-center gap-4 mx-auto lg:!max-w-[50%]"
        >
          <a
            href="https://www.facebook.com/SolidLeisureSolutionsInc.Official"
            target="_blank"
            class="md:hover:bg-[#EBF3FF] rounded-2xl border-[1px] py-[14px] md:px-[35px] px-3.5 md:hover:shadow-md min-h-[4.5rem] sm:min-h-[6rem] inline-flex items-center justify-center"
          >
            <img
              class="w-[100px] md:w-[186px]"
              src="../assets/about_us/FB.png"
              alt=""
            />
          </a>
          <a
            href="https://www.linkedin.com/company/solidleisure-solutions/"
            target="_blank"
            class="md:hover:bg-[#EBF3FF] rounded-2xl border-[1px] py-[14px] md:px-[35px] px-3.5 md:hover:shadow-md min-h-[4.5rem] sm:min-h-[6rem] inline-flex items-center justify-center"
          >
            <img
              class="w-[100px] md:w-[186px]"
              src="../assets/about_us/Linkedin.png"
              alt=""
            />
          </a>
          <a
            href="https://ph.indeed.com/cmp/Solid-Leisure"
            target="_blank"
            class="md:hover:bg-[#EBF3FF] rounded-2xl border-[1px] py-[14px] md:px-[35px] px-3.5 md:hover:shadow-md min-h-[4.5rem] sm:min-h-[6rem] inline-flex items-center justify-center"
          >
            <img
              class="w-[100px] md:w-[186px]"
              src="../assets/about_us/Indeed.png"
              alt=""
            />
          </a>
          <a
            href="https://www.jobstreet.com.ph/en/companies/161252493143697-solid-leisure-solutions-inc"
            target="_blank"
            class="md:hover:bg-[#EBF3FF] rounded-2xl border-[1px] py-[14px] md:px-[35px] px-3.5 md:hover:shadow-md min-h-[4.5rem] sm:min-h-[6rem] inline-flex items-center justify-center"
          >
            <img
              class="w-[100px] md:w-[186px]"
              src="../assets/about_us/Jobstreet.png"
              alt=""
            />
          </a>
          <a
            href="https://www.cakeresume.com/companies/solidleisure-solutions-inc?locale=zh-TW"
            target="_blank"
            class="md:hover:bg-[#EBF3FF] rounded-2xl border-[1px] py-[14px] md:px-[35px] px-3.5 md:hover:shadow-md min-h-[4.5rem] sm:min-h-[6rem] inline-flex justify-center items-center"
          >
            <img
              class="w-[100px] md:w-[186px]"
              src="../assets/about_us/CakeResume.png"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
    <transition name="modal">
      <div v-if="showModal" class="modal-mask">
        <div class="modal-wrapper z-[9999]">
          <div class="modal-container md:w-[65%] w-[90%] rounded-2xl relative">
            <img
              @click="hideModal"
              class="absolute right-5 cursor-pointer md:block hidden w-[45px]"
              src="../assets/who_we_need/eva_close-fill.png"
              alt="photo"
            />
            <img
              @click="hideModal"
              class="absolute right-2 top-2 cursor-pointer md:hidden w-[35px]"
              src="../assets/who_we_need/eva_close-fill.png"
              alt="photo"
            />
            <div
              class="flex items-center md:text-3xl pb-2 text-xl text-[#3E5578]"
            >
              <img
                class="md:block hidden w-[75px] mr-4"
                :src="
                  rolePosition === 'developer'
                    ? roleIconWO_shadow.developer
                    : rolePosition === 'engineer'
                    ? roleIconWO_shadow.engineer
                    : rolePosition === 'design'
                    ? roleIconWO_shadow.designer
                    : rolePosition === 'product'
                    ? roleIconWO_shadow.product
                    : ''
                "
                alt="photo"
              />

              <img
                class="md:hidden w-[55px]"
                :src="
                  rolePosition === 'developer'
                    ? roleIconWO_shadow.developer
                    : rolePosition === 'engineer'
                    ? roleIconWO_shadow.engineer
                    : rolePosition === 'design'
                    ? roleIconWO_shadow.designer
                    : rolePosition === 'product'
                    ? roleIconWO_shadow.product
                    : ''
                "
                alt="photo"
              />

              {{ modalInfo.modalTitle }}
            </div>
            <div class="w-[95%] mx-auto md:text-lg text-base space-y-6 pb-6">
                <div><span class="font-bold">Location:</span> {{ modalInfo.location }}</div>
            </div>
            <div class="w-[95%] mx-auto md:text-lg text-base space-y-6 pb-6">
              <div><span class="font-bold">Job Title:</span> {{ modalInfo.position }}</div>
            </div>
            <div class="modalContent max-h-[500px] overflow-y-scroll">
              <div class="w-[95%] mx-auto md:text-lg text-base space-y-6 pb-6">
                <div class="font-bold">Responsibilities:</div>
                <ul
                  class="list-dist list-inside w-[95%] mx-auto space-y-2 text-[#222222] text-sm"
                >
                  <li
                    v-for="item in modalInfo.jobResponsibility"
                    v-bind:key="item.position"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>

              <div class="w-[95%] mx-auto md:text-lg text-base space-y-6 my-6">
                <div class="font-bold">Skills Required:</div>

                <ul
                  class="list-dist list-inside w-[95%] mx-auto space-y-2 text-[#222222] text-sm"
                >
                  <li
                    v-for="item in modalInfo.skillsRequired"
                    v-bind:key="item.position"
                  >
                    {{ item }}
                  </li>
                </ul>
                <ol
                  v-if="modalInfo.additionalDescription"
                  class="list-dist list-inside w-[95%] mx-auto space-y-2 text-[#222222] text-sm"
                >
                  <div
                    v-if="modalInfo.additionalDescription"
                    class="font-bold text-sm"
                  >
                    {{ modalInfo.additionalDescription.title }}
                  </div>
                  <li
                    v-for="item in modalInfo.additionalDescription.details"
                    v-bind:key="item.position"
                  >
                    {{ item }}
                  </li>
                </ol>
              </div>
            </div>
            <div class="border-t-[.01px] border-white w-full border-down"></div>
            <div class="w-full flex justify-center pt-4">
              <a
                :href="
                  'mailto:recruitment@solidleisure.com?subject=' +
                  modalInfo.position
                "
              >
                <button
                  type="button"
                  class="font-semibold bg-[#22539C] !text-white rounded-full px-6 py-2 text-base transition duration-500 ease select-none md:hover:bg-[#4A7BC6]/[.5] outline-none"
                >
                  Apply Now
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>
<script>
import dev from "../assets/who_we_need/icn_devFilled_shadow.png";
import eng from "../assets/who_we_need/icn_engrFilled_shadow.png";
import design from "../assets/who_we_need/icn_designerFilled_shadow.png";
import prod from "../assets/who_we_need/icn_projmgmtFilled_shadow.png";

import dev_withOut from "../assets/who_we_need/icn_dev.png";
import eng_withOut from "../assets/who_we_need/icn_engr.png";
import design_withOut from "../assets/who_we_need/icn_designer.png";
import prod_withOut from "../assets/who_we_need/icn_projmgmt.png";

import CardsComp from "@/components/CardsComp";
import CardsCompH5 from "@/components/CardsCompH5";

export default {
  name: "WhoWeNeed",
  components: {
    CardsComp,
    CardsCompH5,
  },
  data: function () {
    return {
      rolePosition: "",
      loadMore: true,
      showModal: false,
      role_label: ["All", "Product", "Developer", "Design", "Engineer"],
      active_role: "All",
      roleData: [
        {
          role: "developer",
          details: [
            {
              modalTitle: "C++ Developer (5 days per week)",
              location: "Makati City, Philippines",
              position: "C++ Game Developer",
              experience: "Min 5 years",
              skillsRequired: [
                "Bachelor's Degree in any field of studies.",
                "Minimum 5 years of C++ development foundation.",
                "Have some experience in server development.",
                "Understand multi-threading, network, TCP, UDP, and database.",
                "Good project exposure from start to finish, which can be MFC or QT and other development projects.",
                "Proficient in using GIT, SVN, Visual Studio, and other tools.",
                "Priority will be given to those with working experience in algorithms or large factories.",
                "Have a good work attitude and good communication."
              ],
              jobResponsibility: [
                "You are responsible for the development of Linux system server peripheral functions and daily assistance in troubleshooting.",
                "To work on projects and support functions under MFC or QT and other development projects.",
              ],
            },
            {
              modalTitle: "Android Mobile Developer - (5 days per week)",
              location: "Makati City, Philippines",
              position: "Android Mobile Developer",
              experience: "Min 4 years",
              skillsRequired: [
                "Degree Holder any Bachelor Degree (IT, Sciences, Engineering).",
                "4 years of experience in UX and UE interaction design, with experience in Android and iOS platforms.",
                "Experience in interactive design on desktop and mobile; proficient in using Sketch/Figma/Adobe XD/Axure etc.",
                "Excellent problem-solving skills and familiarity with technical constraints and limitations.",
                "Have excellent innovation and learning skills; excellent communication skills."
              ],
              jobResponsibility: [
                "Design, develop, and implement Android applications.",
                "Strong troubleshooting skills and system maintenance ability.",
                "Analyze and optimize UI and infrastructure application code for quality, efficiency, and performance.",
                "Be able to learn new skills in response to complicated problems.",
                "Communicate with QA, back-end engineers, project manager, and product manager positively to finish given projects on time.",
                "Build reusable Android software components for interfacing with our back-end platforms.",
              ],
            },
            {
              modalTitle: "Front End Developer -VueJS - (5 days per week)",
              location: "Makati City, Philippines",
              position: "Front End Developer - VueJS",
              experience: "Min 5 years",
              skillsRequired: [
                "Bachelor's Degree in any field of studies.",
                "More than 5 years of experience in WEB development, and cooperating with the back end to complete WebAPI and WebSocket data docking.",
                "Project experience in developing web front-end applications, with experience in architecture reconstruction and packaging separation.",
                "Proficient in JavaScript, HTML, CSS.",
                "Familiar with mainstream front-end frameworks such as Vue.js and Node.js, and proficient in one of the front-end frameworks Vue/Angular/React.",
                "Have good coding habits and pay attention to norms and standards.",
                "Have good communication skills and a team player."
              ],
              jobResponsibility: [
                "Responsible for the development of game functions and the maintenance of existing game functions.",
                "Cooperate with operators to discuss design documents, avoid design loopholes, and complete logic implementation.",
                "Ensure game development time and quality, and ensure that the project development cycle is controllable."
              ],
            },
            {
              modalTitle: "AI Media Developer - (5 days per week)",
              location: "Makati City, Philippines",
              position: "AI Media Developer",
              experience: "Min 2 years",
              skillsRequired: [
                "Degree Holder any Bachelor Degree (IT, Sciences, Engineering).",
                "Proficient in C++ and have 2+ years or more of Linux platform live broadcast and on-demand server development experience.",
                "Proficient in RTMP, RTSP, HTTP-FLV, and other streaming media network transmission protocols.",
                "Proficient in audio and video coding and decoding technology.",
                "Familiar with streaming media transcoding and packaging process.",
                "Familiar with common streaming media packaging formats, MP4, FLV, TS, etc.",
                "Proficient in network programming.",
                "Have good communication skills and teamwork spirit.",
              ],
              jobResponsibility: [
                "Participate in the server development of live video and on-demand services.",
                "Participate in the optimization of the media server architecture.",
                "According to the assignment of tasks, complete the corresponding code writing and document writing.",
              ],
            },
            {
              modalTitle: "Senior Java Developer (5 days per week)",
              location: "Makati City, Philippines",
              position: "Senior Java Developer",
              experience: "Min 7 years",
              skillsRequired: [
                "Bachelor's Degree in any field of studies.",
                "7+ years of software design and development experience, large-scale web system research and development is preferred.",
                "Must have worked on 1-2 projects full cycle, able to work on new features, enhancement, and production support; a year as a senior developer.",
                "Proficient in J2EE, Spring, Spring MVC, SpringBoot.",
                "Strong learning ability, excellent logical thinking ability.",
                "Good communication and teamwork skills.",
              ],
              jobResponsibility: [
                "Develop, deploy, and maintain applications on J2EE.",
                "Candidate must have a deep understanding of the basic theory of databases, and be able to use Oracle or MySQL proficiently.",
                "With a good understanding of HTML, CSS, and JS; familiarity with NodeJS is preferred.",
                "Proficiency in Linux system, master common commands.",
              ],
            },
            {
              modalTitle: "Senior Cocos Creator Engineer - (5 days per week)",
              location: "Makati City, Philippines",
              position: "Senior Cocos Creator Engineer",
              experience: "Min 4 years",
              skillsRequired: [
                "Bachelor's Degree in any field of studies.",
                "4-5 years of hands-on coding in Cocos Creator, familiar with the features of various versions, as well as TypeScript or JavaScript language.",
                "More than three years of experience in Cocos game development (bonus points for successful projects online).",
                "Proficient in commonly used design patterns, algorithms, mathematical methods, and optimization in game development.",
                "Familiar with and capable of using common animations such as frame animation, property animation, Spine animation, and DragonBones animation.",
                "Familiar with HTTP, WebSocket, and TCP protocols, as well as the packaging and unpacking of protocol data streams.",
                "Familiar with software development design patterns.",
                "Understanding of H5 DOM API.",
                "Understanding of the Cocos Creator engine extension process.",
                "Familiar with Cocos Creator's native and web architecture principles.",
              ],
              jobResponsibility: [
                "Responsible for the development of the company's H5 games.",
                "Responsible for the development of editors and other auxiliary tools according to the requirements of game functions.",
                "Communicate with planners and backend developers to complete logic and interaction design, etc.",
              ],
            },
          ],
        },
        {
          role: "engineer",
          details: [
            {
              modalTitle: "Applications Test Engineer - (5 days per week)",
              location: "Makati City, Philippines",
              position: "Applications Test Engineer",
              experience: "Min 7 years",
              skillsRequired: [
                "Bachelor's Degree in any field of studies.",
                "Minimum 7 years of experience as QA, Software Tester, or Application/Game Tester.",
                "Knowledge or working capacity in UAT, Functional Testing, Performance Testing, or full-swing game application testing.",
                "Have good communication skills and be a team player.",
              ],
              jobResponsibility: [
                "Responsible for the overall testing of game products, including formulating test plans, designing test cases, and performing game function tests.",
                "Locate quality problems, promote development, and solve quality problems to continuously improve test content and ensure product quality.",
                "Compile test reports, effectively classify and summarize.",
                "Mainly responsible for the function test, protocol & interface test of the PCH5/MH5/APP client and server in the project.",
              ],
            },
            {
              modalTitle: "Network Engineer - (5 days per week)",
              location: "Makati City, Philippines",
              position: "Network Engineer",
              experience: "Min 4 years",
              skillsRequired: [
                "Degree Holder any Bachelor Degree (IT, Sciences, Engineering).",
                "4 years of experience in resolving and providing solutions for network requirements, participating in business architecture optimization, disaster recovery, application performance, and other projects to ensure data and business security.",
                "Responsible for organizing and writing comprehensive software engineering documents, proficient in using Swift development language and Apple Store online process.",
                "Responsible for the coding implementation of core business and technical components.",
                "CCNA or other network-related certification is a plus.",
                "Able to handle and solve problems in projects independently.",
              ],
              jobResponsibility: [
                "Responsible for the communication of network requirements, participating in business architecture optimization, disaster recovery optimization, application performance optimization, and other projects to ensure data and business security, and ensure the control and improvement of the deployment structure of business products, IDC distribution, HA scheme, etc. from the network.",
                "Design, develop, and maintain iPhone client software according to requirements.",
                "Review the implementation plan submitted by front-line engineers and put forward guiding suggestions.",
                "As a second-line engineer, lead the preparation of major network adjustments and network optimization plans, and guide the first-line engineers to implement them.",
                "Guide the front-line engineers in compiling network technical service regulations to ensure the standardization of on-site services.",
                "Participate in the handling of major network failures, conduct detailed analysis of common problems and recurring failures during network operation and maintenance, and submit corresponding problem solutions.",
                "Routine inspection and continuous optimization of core network and security equipment.",
                "Complete other tasks from superiors.",
              ],
            },
            {
              modalTitle: "IT Helpdesk Engineer - (5 days per week)",
              location: "Makati City, Philippines",
              position: "IT Helpdesk Engineer",
              experience: "Min 1 year",
              skillsRequired: [
                "Degree Holder any Bachelor Degree (IT, Sciences, Engineering).",
                "At least 2 years of working experience in the related field is required for this position.",
                "Applicant must be willing to do field work and support.",
                "Must be knowledgeable and experienced with the different roles of servers such as active directory, attendance server, DHCP, and print server, VDI, and Exchange server.",
                "Must be knowledgeable and experienced with different types of Windows and Apple operating systems, Windows Server, and Windows PE.",
                "Must be knowledgeable with computer systems and network topology and troubleshooting.",
                "Strong troubleshooting and analytical skills.",
                "Can work with minimum supervision and is a team player.",
                "Willing to work overtime.",
              ],
              jobResponsibility: [
                "To provide quick IT resolutions, construct reasonable structure, reasonable cost, and strong scalability of its services.",
                "Responsible for the company's IT system construction and selection, management, and maintenance, to ensure the stable operation of infrastructure services and business.",
                "Responsible for the operation maintenance and monitoring of the company's AD, EXCHANGE, SQL, and other servers.",
                "Compile company IT-related documents and system training materials, provide corresponding training, etc.",
                "Efficient learning ability can quickly expand personal knowledge and improve IT skills according to the company's business development.",
              ],
            },
          ],
        },
        {
          role: "design",
          details: [
            // Design
          ],
        },
        {
          role: "product",
          details: [
            {
              modalTitle: "Software Product Manager - (5 days per week)",
              location: "Makati City, Philippines",
              position: "Software Product Manager",
              experience: "Min  3 years",
              skillsRequired: [
                "Bachelor's Degree in any field of studies.",
                "Must have 6-8 years in the Mobile /E-commerce/Fintech Industry as Product Owner / Product Founder or Product Manager.",
                "Proven track record in managing the product development lifecycle.",
                "With a product portfolio is a must-have.",
                "Language Requirement: Mandarin is mandatory.",
              ],
              jobResponsibility: [
                "The product manager defines the application/product vision and strategy.",
                "Manages the full product development lifecycle.",
                "Provide clear recommendations from market research, benchmarks, and application data to help define and prioritize the backlog, to continuously improve features.",
                "Test ongoing tasks and ensure that everything is on schedule.",
                "Conducts market research and competitive analysis.",
                "Manages the project budget and timeline.",
                "Monitors in-project metrics and KPI.",
                "Collaborates with other teams and functions of the company.",
                "Communicate with the stakeholders.",
                "Manages the application soft-launch, launch.",
                "Manages the update and maintenance of the application.",
                "Identifies and implements monetization strategies.",
                "Collects and analyses player feedback and analytics.",
                "Ensures the game's quality and success in the marketplace.",
              ],
            },
            {
              modalTitle: "Project Manager - (5 days per week)",
              location: "Makati City, Philippines",
              position: "Project Manager",
              experience: "Min 4 years",
              skillsRequired: [
                "Degree Holder any Bachelor Degree (IT, Sciences, Engineering).",
                "With a minimum of 4 years of software project management experience.",
                "With rich experience in technical teamwork and cooperation.",
                "Have good communication skills and be familiar with the software project process.",
                "Good document writing ability and ability to withstand pressure.",
              ],
              jobResponsibility: [
                "Responsible for the project management of the platform system, maintaining efficient communication with partners and business departments, changing according to needs, and delivering tasks on time.",
                "Responsible for cross-group or cross-department demand communication and work development.",
                "Establish and maintain project management system, project kanban, and other project progress display tools.",
                "Find, track, and solve project problems in time, and effectively manage project risks.",
              ],
            },
          ],
        },
      ],

      modalInfo: {},
      roleIcon: {
        developer: dev,
        engineer: eng,
        designer: design,
        product: prod,
      },
      roleIconWO_shadow: {
        developer: dev_withOut,
        engineer: eng_withOut,
        designer: design_withOut,
        product: prod_withOut,
      },
    };
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.showModal = false;
      }
    });
  },
  methods: {
    loadMoreModal() {
      this.loadMore = !this.loadMore;
    },
    showModalMethod(content, pos) {
      this.modalInfo = content;
      this.showModal = !this.showModal;
      this.rolePosition = pos;
    },
    hideModal() {
      this.showModal = false;
    },
    setActiveRole(val) {
      //
      document.querySelector("#mobileJobPosting").scrollTop = 0;
      // document.getElementById('mobileJobPosting').scrollTop = 0
      this.active_role = val;
      // console.log(this.role_label)
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 1020px) and (min-width: 770px) {
}

#mobileJobPosting {
  scroll-behavior: smooth !important;
}

.cards-enter-active,
.cards-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease;
}

.cards-enter-active {
  transition-delay: 0.5s;
}

.cards-enter {
  opacity: 0;
  transform: translateY(-100px);
}

.cards-enter-to {
  opacity: 1;
  transform: translateY(0px);
}

.cards-leave {
  opacity: 1;
  transform: translateY(0px);
}

.cards-leave-to {
  opacity: 0;
  transform: translateY(100px);
}

.border-down {
  -webkit-box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 0px -2px 3px 0px rgba(0, 0, 0, 0.35);
}

.modalContent::-webkit-scrollbar {
  width: 8px;
}

.modalContent::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  /*background-color: #F5F5F5;*/
}

.modalContent::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
  /*outline: 1px solid slategrey;*/
}

#CAREERS {
  scroll-margin-top: 10em;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.activeJob:after {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  background: #faad14;
  height: 8px;
  border-radius: 30px;
}

.borderTop {
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  background: #e5e7eb;
  height: 8px;
}

.borderDuloLeft {
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  background: #e5e7eb;
  height: 8px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.borderDuloRight {
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  background: #e5e7eb;
  height: 8px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.maxwidth {
  max-width: 50% !important;
}

.modal-mask ul {
  list-style: unset;
}
</style>
