<template>
  <section id="CONTACTUS" class="bg-white">
    <div class="relative overflow-hidden">
      <div class="relative">
        <img class="absolute top-[-100px] -right-40 z-0" src="../assets/contact_us/Art.png"/>
      </div>
      <div class="pt-0 md:pt-10">
        <img class="mx-auto md:block hidden w-[40%]" src="../assets/contact_us/contactUsSection.png"/>
        <img class="mx-auto md:hidden py-[5vh] w-[330px]" src="../assets/contact_us/title-mobile.png"/>
      </div>
      <div class="sm:hidden relative">
        <div class="font-normal text-base w-[90%] mx-auto space-y-4 pl-4 z-[400]">
          <!--          font-[HelveticaNeue] tracking-widest -->
          <div class="flex space-x-6">
            <div class="my-auto">
              <img class="min-w-[30px] max-w-[30px]" src="../assets/contact_us/location.png"/>
            </div>
            <span class=" text-[#666666] md:px-2 pl-[2px]">14th Floor Tower 2, RCBC Plaza Ayala Ave. Cor. Sen Gil Puyat Brgy. Bel-Air Makati City 1209</span>
          </div>
          <div class="flex space-x-6 ">
            <div class="my-auto ">
              <img class="min-w-[30px] max-w-[30px]" src="../assets/contact_us/telephone.png"/>
            </div>
            <span class=" text-[#666666] mt-[2px]"> +639176339180 </span>
          </div>
          <div class="flex space-x-6">
            <div class="my-auto">
              <img class="min-w-[30px] max-w-[30px]" src="../assets/contact_us/mail.png"/>
            </div>
            <a href="mailto:recruitment@solidleisure.com" class=" text-[#666666]"><span class="pt-1">recruitment@solidleisure.com</span></a>
          </div>
        </div>

        <div class=""><img class="" src="../assets/contact_us/LogowithPeople_mob.png"></div>

      </div>
      <div class="mt-10 sm:flex hidden justify-center relative  font-normal  text-base">
        <!--        font-[HelveticaNeue] tracking-widest-->
        <img class="xl:block hidden w-full" src="../assets/contact_us/LogowithPeople_pc.png">
        <img class="xl:hidden " src="../assets/contact_us/LogowithPeople_mob.png">
        <div class="flex flex-col text-2xl absolute xl:top-[10%] right-[10%] pl-[15%] contactUsInfo">
          <div class="flex">
            <div class="">
              <img class="p-2 min-w-[60px] max-w-[60px]" src="../assets/contact_us/location.png"/>
            </div>
            <span class="content-center self-center p-2 text-[#666666] md:px-2">14th Floor Tower 2, RCBC Plaza Ayala Ave.<br/>Cor. Sen Gil Puyat Brgy. Bel-Air Makati City 1209</span>
          </div>
          <div class="flex my-4">
            <div class="">
              <img class=" p-2 min-w-[60px] max-w-[60px]" src="../assets/contact_us/telephone.png"/>
            </div>
            <span class="content-center self-center p-2 text-[#666666]"> +639176339180 </span>
          </div>
          <div class="flex">
            <div class="">
              <img class="p-2 min-w-[60px] max-w-[60px]" src="../assets/contact_us/mail.png"/>
            </div>
            <a href="mailto:recruitment@solidleisure.com" class="content-center self-center p-2 text-[#666666]">recruitment@solidleisure.com</a>
          </div>
        </div>
      </div>
      <div>
        <!--        <div class="grid grid-cols-1 mx-[10%] mt-20 md:grid-cols-2">-->
        <!--          <img class="md:order-first order-last" src="../assets/contact_us/LogowithPeople_pc.png">-->
        <!--          <div class="flex-col">-->
        <!--            <div class="flex justify-center mb-5">-->
        <!--              <span class="text-gray-400">We'd love to hear from <b>YOU!</b></span>-->
        <!--            </div>-->
        <!--            <div class="flex mb-5 justify-center">-->
        <!--              <input class="w-[100%] border border-gray-500 rounded-md p-3 md:w-[70%]" type="text" placeholder="Full Name">-->
        <!--            </div>-->
        <!--            <div class="flex mb-5 justify-center">-->
        <!--              <input class="w-[100%] border border-gray-500 rounded-md p-3 md:w-[70%]" type="text" placeholder="Email">-->
        <!--            </div>-->
        <!--            <div class="flex mb-5 justify-center">-->
        <!--              <textarea class="w-[100%] h-[200px] border border-gray-500 rounded-md p-3 md:w-[70%]" type="text" placeholder="Message"></textarea>-->
        <!--            </div>-->
        <!--            <div class="flex justify-center">-->
        <!--              <button class="bg-[#22539C] w-[130px] hover:bg-[#4A7BC6] border rounded-full px-6 py-1.5 text-white text-[16px]" type="submit">Submit</button>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </section>
</template>
<script>
// import SLLogo from "../assets/SL Logo.png"
export default {
  name: "ContactUs",
  // data: function () {
  //   return {
  //     SLLogo: SLLogo
  //   }
  // }
};
</script>
<style>
#CONTACTUS {
  scroll-margin-top: 6em;
}

@media screen and (max-width: 1455px) and (min-width: 1280px) {
  .contactUsInfo {
    right: 5% !important;
  }
}
</style>
