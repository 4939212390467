<template>
  <div>
    <header class="bg-[#181818] fixed top-0 z-[9998] w-full shadow-xl">
      <nav class="w-[90%] mx-auto">
        <div class="flex justify-between items-center lg:h-[100px]">
          <div class="sm:hidden">
            <a href="#HOME">
              <img
                  src="../assets/SLLogo_h5.png"
                  class="lg:h-full h-[95px] py-6"
                  alt="Solid Leisure Logo"
              />
            </a>
          </div>
          <a class="lg:h-full h-[95px] sm:block hidden py-6 sm:flex" href="#HOME">
            <img
                src="../assets/SLLogo_h5.png"
                alt="Solid Leisure Logo"
            />
          </a>
          <img
              v-if="!mobile_nav"
              src="../assets/hamburger_menu.png"
              class="lg:hidden h-[35px] cursor-pointer icontrans"
              @click="openMobileNav"
              alt="Solid Leisure Logo"
          />
          <img
              v-if="mobile_nav"
              src="../assets/menu_close.png"
              class="lg:hidden h-[35px] cursor-pointer icontrans"
              @click="closeMobileNavButton"
              alt="Solid Leisure Logo"
          />
          <ul class="lg:flex hidden text-lg space-x-16 text-white  h-[100%]  ">
            <li class="relative tracking-wider  font-normal flex items-center " v-for="(item) in menu"
                :class="activeMenu === item.replace(' ', '') ? 'activeMenu ' : '' " v-bind:key="item.id"><a
                :href="'#'+item.replace(' ', '')">{{ item }}</a></li>
          </ul>

        </div>
      </nav>
    </header>
    <transition name="view">
      <div v-if="mobile_nav" class="nav-mask  flex backdrop">
        <ul class="text-3xl space-y-16 text-center text-white my-auto text-center w-full ">
          <li class="relative menusxMobile" v-for="item in menu" v-bind:key="item.id"
              :class="activeMenu === item.replace(' ', '') ? 'activeMobileMenu ' : ''"><a
              @click="closeMobileNav(item.replace(' ', ''))">{{ item }}</a></li>
        </ul>
      </div>
    </transition>
    <a :class="showGoTop ? '' : 'hidden'" href="#HOME">
      <div
          class="fixed top-[75%] right-[1vh] rounded-full bg-[#3E5578] md:hover:bg-[#4A7CC6] active:bg-[#4A7CC6] md:h-[43px] h-[35px] md:w-[43px] w-[35px] z-[9992]">
        <img class="m-auto md:mt-[14px] mt-[10px]  w-[60%]" src="../assets/Vector.png"/>
      </div>
    </a>
  </div>
</template>
<script>
// import SLLogo from "../assets/SLLogo.png"
export default {
  name: "NavBar",
  data: function () {
    return {
      menu: ['HOME', 'ABOUT US', 'SERVICES', 'CAREERS', 'CONTACT US'],
      activeMenu: 'HOME',
      currentPos: 0,
      mobile_nav: false,
      showGoTop: false,
    }
  },
  methods: {
    trackMenuMethod() {
      this.currentPos = window.top.scrollY

      let heightLanding = document.getElementById('HOME').clientHeight
      let heightAboutUs = document.getElementById('ABOUTUS').clientHeight
      let heightServices = document.getElementById('SERVICES').clientHeight
      let heightCareer = document.getElementById('CAREERS').clientHeight
      let heightContactUs = document.getElementById('CONTACTUS').clientHeight
      let heightGallery = document.getElementById('gallery').clientHeight
      // console.log(this.currentPos,heightLanding + heightAboutUs)
      if (this.currentPos <= heightLanding - 160) {
        this.activeMenu = this.menu[0].replace(' ', '')
        this.showGoTop = false
      } else if (this.currentPos < (heightLanding + heightAboutUs - 160)) {
        this.activeMenu = this.menu[1].replace(' ', '')
        this.showGoTop = false
      } else if (this.currentPos < (heightLanding + heightAboutUs + heightServices)) {
        this.activeMenu = this.menu[2].replace(' ', '')
        this.showGoTop = false
      } else if (this.currentPos < (heightLanding + heightAboutUs + heightServices + heightCareer)) {
        this.activeMenu = this.menu[3].replace(' ', '')
        this.showGoTop = true
      } else if (this.currentPos < (heightLanding + heightAboutUs + heightServices + heightCareer + heightContactUs + heightGallery)) {
        this.activeMenu = this.menu[4].replace(' ', '')
        this.showGoTop = true
      }
    },
    openMobileNav() {
      this.mobile_nav = true
      this.trackMenuMethod()
      // document.querySelector(".nav-mask").style.width = "100%"
    },
    closeMobileNavButton() {
      this.mobile_nav = false
      this.trackMenuMethod()
    },
    closeMobileNav(item) {
      // document.querySelector(".nav-mask").style.width = "0"
      let heightLanding = document.getElementById('HOME').clientHeight
      let heightAboutUs = document.getElementById('ABOUTUS').clientHeight
      let heightServices = document.getElementById('SERVICES').clientHeight
      let heightCareer = document.getElementById('CAREERS').clientHeight
      let heightContactUs = document.getElementById('CONTACTUS').clientHeight
      let heightGallery = document.getElementById('gallery').clientHeight
      if (item === 'HOME') {

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
      if (item === 'ABOUTUS') {

        document.body.scrollTop = heightLanding;
        document.documentElement.scrollTop = heightLanding;
      }
      if (item === 'SERVICES') {
        document.body.scrollTop = heightLanding + heightAboutUs;
        document.documentElement.scrollTop = heightLanding + heightAboutUs;
      }
      if (item === 'CAREERS') {
        document.body.scrollTop = heightLanding + heightAboutUs + heightServices;
        document.documentElement.scrollTop = heightLanding + heightAboutUs + heightServices;
      }
      if (item === 'CONTACTUS') {
        document.body.scrollTop = heightLanding + heightAboutUs + heightServices + heightCareer + heightContactUs + heightGallery;
        document.documentElement.scrollTop = heightLanding + heightAboutUs + heightServices + heightCareer + heightGallery;
      }
      this.activeMenu = item
      this.mobile_nav = false
    },
    clickNav(val) {

      this.activeMenu = val
    },
    handleScroll() {
      this.trackMenuMethod()
      // console.log(this.activeMenu)
      // else if (currentPos.value > height && currentPos.value < (height * 2)){
      //   activeMenu.value = menu.value[1]
      // } else if (currentPos.value > height && currentPos.value < (height * 3)){
      //   activeMenu.value = menu.value[2]
      // } else if (currentPos.value > height && currentPos.value < (height * 4)){
      //   activeMenu.value = menu.value[3]
      // }

    },
    handleBeforeLoad() {
      window.scrollTo(0, 0);
    },
    // handleScroll () {
    //   const sections = document.querySelectorAll("section");
    //   const navLi = document.querySelectorAll(".menusx");
    //   const navLiMob = document.querySelectorAll(".menusxMobile");
    //   this.currentPos = window.top.scrollY
    //   let heightLanding = document.getElementById('HOME').clientHeight
    //   let heightAboutUs = document.getElementById('ABOUTUS').clientHeight
    //   let heightCareer = document.getElementById('CAREERS').clientHeight
    //   let heightContactUs = document.getElementById('CONTACTUS').clientHeight
    //   // let heightGallery = document.getElementById('gallery').clientHeight
    //
    //
    //   if (this.currentPos <= heightLanding - 160) {
    //     this.activeMenu = this.menu[0]
    //     this.showGoTop = false
    //   }
    //   else if (this.currentPos  < (heightLanding + heightAboutUs - 160)) {
    //     this.activeMenu = this.menu[1]
    //     this.showGoTop = false
    //   }
    //   else if (this.currentPos < (heightLanding + heightAboutUs + heightCareer) ) {
    //     this.activeMenu = this.menu[2]
    //     this.showGoTop = true
    //   }
    //   else if (this.currentPos < (heightLanding + heightAboutUs + heightCareer + heightContactUs )) {
    //     this.activeMenu = this.menu[0]
    //     this.showGoTop = true
    //   }
    //
    //
    //
    //   var current = "";
    //
    //   sections.forEach((section) => {
    //
    //     const sectionTop = section.offsetTop;
    //     if (window.top.scrollY >= sectionTop) {
    //       // console.log(section.getAttribute("id"))
    //       current = section.getAttribute("id");
    //
    //     }
    //   });
    //   navLi.forEach((li) => {
    //     li.classList.remove("activeMenu");
    //
    //
    //
    //     if (li.classList.contains(current)) {
    //       li.classList.add("activeMenu");
    //
    //     }
    //   });
    //   navLiMob.forEach((li) => {
    //     li.classList.remove("activeMobileMenu");
    //     if (li.classList.contains(current)) {
    //       li.classList.add("activeMobileMenu");
    //     }
    //   });
    //
    //
    //
    //
    //
    //
    //
    //
    //
    // }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    // window.addEventListener('beforeunload', this.handleBeforeLoad)
    window.addEventListener('unload', this.handleBeforeLoad)

  },
  mounted() {


  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.scrollTo(0, 0);
  },

};
</script>
<style>

.view-enter-active, .view-leave-active {
  transition: opacity 0.2s ease-in-out, transform 0.2s ease;
}

.view-enter-active {
  transition-delay: 0.2s;
}

.view-enter {
  opacity: 0;
  transform: translateX(100px);
}

.view-enter-to {
  opacity: 1;
  transform: translateX(0px);
}

.view-leave {
  opacity: 1;
  transform: translateX(0px);
}

.view-leave-to {
  opacity: 0;
  transform: translatex(100px);
}


/*@font-face {*/
/*  font-family: HelveticaNeue;*/
/*  src: url(../assets/helveticaneue/HelveticaNeue.ttf);*/
/*}*/
/*@font-face {*/
/*  font-family: HelveticaNeueLt;*/
/*  src: url(../assets/helveticaneue/HelveticaNeueLt.ttf);*/
/*}*/
/*@font-face {*/
/*  font-family: HelveticaNeueMed;*/
/*  src: url(../assets/helveticaneue/HelveticaNeueMed.ttf);*/
/*}*/
/*@font-face {*/
/*  font-family: HelveticaNeueBD;*/
/*  src: url(../assets/helveticaneue/HelveticaNeueMed.ttf);*/
/*}*/

/*.fontHelvetica {*/
/*  font-family: HelveticaNeue !important;*/
/*  letter-spacing: 0.15em !important;*/
/*  font-weight: 400 !important;*/
/*}*/

.backdrop {
  backdrop-filter: blur(24px) !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.activeMobileMenu {
  font-weight: 700;
  color: #FAAD14;
  transition: 0.3s;
}

.activeMenu {
  /*font-family: HelveticaNeueBD !important;*/
  /*letter-spacing: 0.15em !important;*/
  font-weight: 700 !important;
  transition: 0.3s;

}

.activeMenu:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  height: 4px;
  border-radius: 30px;
}

.icontrans {
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0.3s
}

.nav-mask {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  /*transition: opacity 0.8s ease-in-out;*/
  /*transition-delay: 0.3s;*/
}
</style>
