<template>
  <section id="ABOUTUS" class="bg-white">
    <div class="">
      <img class="mx-auto md:block hidden mt-[150px] w-[40%]" src="../assets/about_us/AboutUsSection.png"/>
      <img class="mx-auto md:hidden py-[5vh] w-[285px]" src="../assets/about_us/h5aboutus.png"/>
      <div class="md:text-2xl text-md md:text-start text-center w-[80%] mx-auto text-[#999999]">
        <!--        fontHelvetica-->
        SOLIDLEISURE SOLUTIONS INC. is a company with expertise in providing outsourcing
        services for all processes that include data voices or collections,
        technical support, back-office support scientific analysis, and research.
        It also provides consultancy software and services that are normally offered by
        the outsourcing business information technology services providers, the software
        development house and application services providers, and accounting services for
        internal management purposes only without rendering reports to be submitted to any
        government instrumentalities.
      </div>
      <div class="lg:mt-[150px] md:text-2xl text-md lg:text-start text-center overflow-hidden">
        <div class="flex lg:flex-row flex-col items-center w-[80%] mx-auto">
          <img v-animate.repeat.fade="'slide-right'" class="md:w-[35%]" src="../assets/about_us/icn_mission.png" alt="">
          <div v-animate.repeat.fade="'slide-left'" class="space-y-8 ">
            <div class="font-bold text-[#2e2e2e] text-[25px]">Our Mission</div>
            <div class="text-[#666666] ">To provide best-of-breed software products to enable an
              organization to run
              its businesses and operations betters.
              To deliver effective IT solutions and quality services to enhance the competitive advantages of our
              clients. And, to maintain a sustainable social environment and be a socially responsible corporate
              citizen.
            </div>
          </div>
        </div>
        <div class="flex lg:flex-row flex-col items-center w-[80%] mx-auto">
          <img v-animate.repeat.fade="'slide-right'" class="md:w-[35%]" src="../assets/about_us/icn_vision.png" alt="">

          <div v-animate.repeat.fade="'slide-left'" class="space-y-8 ">

            <div class="font-bold text-[#2e2e2e] text-[25px]">Our Vision</div>
            <!--            font-[HelveticaNeueBD]-->
            <div class="text-[#666666] ">
              <!--              font-[HelveticaNeueLt]-->
              To build better world with Information Technology.
              To serve as the framework and guides every aspect of our business
              by unfolding what we need to achieve to continue achieving customer experience
              and quality growth.
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>
<script>
// import SLLogo from "../assets/SLLogo.png"
export default {
  name: "AboutUs",
  // data: function () {
  //   return {
  //     SLLogo: SLLogo
  //   }
  // }
};
</script>
<style scoped>
.fontHelvetica {
  font-family: HelveticaNeueLt !important;
  letter-spacing: 0.01em !important;
  line-height: 30px;
}

#ABOUTUS {
  scroll-margin-top: 10em;
}

.animate {
  transition-delay: .4s;
  transition-duration: .5s;
  transition-timing-function: ease
}

.slide-right {
  /*transform: translateY(-100px)*/
  opacity: 0;
  transform: translateX(-50px);
}

.slide-right.animate-active {
  /*transform: translateY(0)*/
  opacity: 1;
  transform: translateX(0);
}

.slide-left {
  /*transform: translateY(-100px)*/
  opacity: 0;
  transform: translateX(50px);
}

.slide-left.animate-active {
  /*transform: translateY(0)*/
  opacity: 1;
  transform: translateX(0);
}


</style>
