<template>
  <div  class="flex bg-white border-b-[.5px] py-6 flex-col justify-center">
    <div class="ml-[10px] flex items-center ">
      <div class="mb-2 mr-2">
        <img class="w-[60px] " :src="itemUpper.role === 'developer' ? roleIcon.developer : itemUpper.role === 'engineer' ? roleIcon.engineer : itemUpper.role === 'design' ? roleIcon.designer : itemUpper.role === 'product' ? roleIcon.product : '' "  alt="photo">
      </div>
      <p class="text-lg text-gray-700 font-bold mb-4 pt-2 font-semibold ">{{ item.position }}</p>
<!--      font-[HelveticaNeueLt] tracking-wider -->
    </div>
    <div class="w-[85%] mx-auto">
      <ul class="  text-base list-disc text-gray-400 font-normal list-inside font-normal ">
        <li>{{item.experience}}</li>
        <li>Within Metro Manila</li>
      </ul>
      <div  class=" flex justify-between space-x-6 my-[20px]">
        <a :href="'mailto:recruitment@solidleisure.com?subject='+item.position">
          <div

              class="font-semibold bg-[#22539C] text-white rounded-full sm:px-6 px-4 py-2 text-lg transition duration-500 ease select-none md:hover:bg-[#4A7BC6]/[.5] outline-none "
          >
              Apply Now
          </div>
        </a>
        <div @click="showModalMethod(item,itemUpper.role)"  class="cursor-pointer flex items-center">
          <span class="text-lg  text-[#22539C] text-gray-700 font-semibold ">Read More </span>
<!--          tracking-wider font-[HelveticaNeueLt]-->
          <img class="ml-4 mb-1 mt-2" src="../assets/who_we_need/arrowblue.png"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default  {
  props: ['index','indexX','itemUpper','roleIcon','item','showModalMethod']
}
</script>
