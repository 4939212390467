<template>
  <div class="flex bg-white shadow-md border-[.5px]  rounded-2xl py-6 flex-col justify-center items-center">
    <div class="">
      <div class="mb-2 ">
        <img class="mx-auto " :src="itemUpper.role === 'developer' ? roleIcon.developer : itemUpper.role === 'engineer' ? roleIcon.engineer : itemUpper.role === 'design' ? roleIcon.designer : itemUpper.role === 'product' ? roleIcon.product : '' "      alt="photo">
       </div>
      <p class=" text-2xl text-gray-700 font-bold mb-4 pt-0 font-normal ">{{ item.position }}</p>
<!--      font-[HelveticaNeueBD] tracking-wider  tracking-tight-->
    </div>
    <div class="px-0">
      <ul class="text-lg list-disc text-gray-400  list-inside  ">
<!--        font-[HelveticaNeueLt] tracking-wider-->
        <li>{{item.experience}}</li>
        <li>Within Metro Manila</li>
      </ul>
      <div  class=" flex justify-between space-x-6 my-[20px]">
        <a :href="'mailto:recruitment@solidleisure.com?subject='+item.position">
          <div

              class="font-bold  bg-[#22539C] text-white rounded-full px-6 py-2 text-base transition duration-500 ease select-none hover:bg-[#4A7BC6]/[.5] outline-none active:bg-[#22539C] active:text-[#103060]"
          >
              Apply Now
          </div>
        </a>
        <div @click="showModalMethod(item,itemUpper.role)"  class="cursor-pointer flex items-center">
          <span class="text-base text-[#22539C] text-gray-700 font-bold ">Read More </span>
<!--          tracking-wider font-[HelveticaNeueLt]-->
          <img class="ml-4 mb-1 mt-2" src="../assets/who_we_need/arrowblue.png"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default  {
  props: ['index','indexX','itemUpper','roleIcon','item','showModalMethod']
}
</script>
